<template>
  <v-container fluid tag="section" class="px-4">
    <h1 class="text--secondary">Export Sales Data</h1>
    <p>
      Download your sales spreadsheet here. Please note orders are exported in
      accordance of their timestamps.
    </p>
    <div class="white br-8 mb-8 --report-controls px-4 pt-2 pb-4 pb-md-0">
      <div>
        <v-menu
          class="d-inline-block"
          v-model="dates_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="unset"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formated_dates"
              label="Select date range *"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              hide-details
              @click:clear="dates = []"
            >
              <template #prepend>
                <v-icon v-text="'$calendar'" color="primary" />
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="dates" range></v-date-picker>
        </v-menu>
      </div>

      <v-btn
        rounded
        :block="$vuetify.breakpoint.smAndDown"
        color="primary"
        class="mr-0"
        :disabled="loading == 'orders' || dates.length !== 2"
        @click="download_report('orders')"
        ><div v-if="loading !== 'orders'">
          <v-icon class="mr-2">mdi-cloud-download-outline</v-icon
          ><span class="font-weight-bold">Download Orders</span>
        </div>
        <v-progress-circular v-else indeterminate size="16" color="primary"
      /></v-btn>
      <v-btn
        rounded
        :block="$vuetify.breakpoint.smAndDown"
        color="primary"
        class="mr-0"
        :disabled="loading === 'products' || dates.length !== 2"
        @click="download_report('products')"
        ><div v-if="loading !== 'products'">
          <v-icon class="mr-2">mdi-cloud-download-outline</v-icon
          ><span class="font-weight-bold">Download Products</span>
        </div>
        <v-progress-circular v-else indeterminate size="16" color="primary" />
      </v-btn>
    </div>
    <template v-if="shop.allow_tip">
      <h1 class="text--secondary">Total Tip (Last 30 Days)</h1>
      <template v-if="tips_sum > 0">
        <p>
          Current total is <b>£{{ tips_sum }}</b
          >.
        </p>

        <div class="tips-chart white br-8 px-4 py-2">
          <canvas id="tipsChart" width="400" height="400"></canvas>
        </div>
      </template>
      <template v-else>
        <v-alert
          type="info"
          colored-border
          border="left"
          color="primary"
          class="mt-4 d-inline-block"
          >Sorry, you have not received any tips in the past 30 days.</v-alert
        >
      </template>
    </template>
  </v-container>
</template>
<script>
import moment from "moment";
import Chart from "chart.js/auto";

import { download_order_reports } from "@/requests";
import { mapState } from "vuex";

export default {
  name: "Reports",
  data() {
    return {
      dates_menu: false,
      loading: false,
      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop", "shop_id", "tips"]),
    formated_dates() {
      if (!this.dates.length) return "Select date range";
      let [from, to] = this.dates;

      let ret = "";

      if (from) ret += `From ${this.format_date_to_uk_format(from)} `;
      if (to) ret += `to ${this.format_date_to_uk_format(to)}`;
      return ret;
    },
    tips_data() {
      const format = "YYYY-MM-DD";
      const thirty_days_ago = moment().subtract(30, "days");
      const labels = [thirty_days_ago.format("DD/MM")];
      const data = [this.tips?.[thirty_days_ago.format(format)] ?? 0];

      for (let i = 1; i <= 30; i++) {
        const label = thirty_days_ago.add(1, "days");
        data.push(this.tips?.[label.format(format)] ?? 0);
        labels.push(label.format("DD/MM"));
      }

      return { labels, data };
    },
    tips_sum() {
      return this.tips_data.data.reduce((a, b) => a + b, 0);
    },
  },
  mounted() {
    if (this.tips_sum > 0) {
      this.draw_tips_chart();
    }
  },
  methods: {
    format_date_to_uk_format(iso_date) {
      return moment(iso_date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    async download_report(type) {
      this.loading = type;
      try {
        let [from, to] = this.dates;
        const response = await download_order_reports({
          from,
          to,
          shop_id: this.shop_id,
          export_type: type,
        });
        this.forceFileDownload(response, `${type}-${from}-${to}.csv`);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
    draw_tips_chart() {
      const ctx = document.getElementById("tipsChart").getContext("2d");
      const data = this.tips_data;
      new Chart(ctx, {
        type: "line",
        data: {
          labels: data.labels,
          datasets: [
            {
              label: "Tips (£)",
              data: data.data,
              borderColor: "#54c4c9",
              cubicInterpolationMode: "monotone",
              tension: 0.4,
              pointStyle: "circle",
              pointRadius: 4,
            },
          ],
        },
        options: {
          interaction: {
            intersect: false,
            mode: "index",
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.--report-controls {
  display: grid;
  align-items: center;
  row-gap: 1rem;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 3fr 2fr 2fr 2fr;
    column-gap: 1rem;
  }
}
.tips-chart {
  max-height: 300px;
  max-width: 100%;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 50%;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":"","tag":"section"}},[_c('v-row',{staticClass:"secondary px-4 br-8 pt-0 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.statuses,"label":" Filter by Status","hide-details":"","clearable":""},model:{value:(_vm.status_filter),callback:function ($$v) {_vm.status_filter=$$v},expression:"status_filter"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-5 py-3",attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.routes,"item-key":"id","search":_vm.search,"items-per-page":10,"single-expand":true,"show-expand":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left font-weight-bold"},[_vm._v("Order")]),_c('th',{staticClass:"text-left font-weight-bold"},[_vm._v("Shop")]),_c('th',{staticClass:"text-left font-weight-bold"},[_vm._v("Customer")]),_c('th',{staticClass:"text-left font-weight-bold"},[_vm._v("Phone")]),_c('th',{staticClass:"text-left font-weight-bold"},[_vm._v("Address")]),_c('th',{staticClass:"text-left font-weight-bold"},[_vm._v("Note")])])]),_c('tbody',_vm._l((item.orders),function(order){return _c('tr',{key:order.id},[_c('td',[_vm._v(_vm._s(order.order_no))]),(order.shop && order.shop.id !== _vm.shop_id)?_c('td',[_c('v-icon',{on:{"click":function($event){(_vm.shop_details_dialog = true),
                              (_vm.selected_order = order)}}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(order.shop.name)+" ")],1):_c('td',[_vm._v(" "+_vm._s(_vm.shop.name)+" ")]),_c('td',[_vm._v(_vm._s(order.customer.name))]),_c('td',[_vm._v(_vm._s(order.customer.phone_no))]),_c('td',[_vm._v(_vm._s(order.delivery.address))]),_c('td',[_vm._v(_vm._s(order.customer.note || "-"))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.orders",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(item.orders.length)+" ")]}},{key:"item.created_at",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date_created_at(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
                              var item = ref.item;
return [_c('v-chip',{class:("order-status-chip " + (item.status)),attrs:{"dark":""}},[_vm._v(_vm._s(_vm.ORDER_STATUSES[item.status].name))])]}},{key:"item.maps",fn:function(ref){
                              var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","rounded":"","text":"","href":_vm.route_maps_url(item),"target":"_blank","color":"primary"}},[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s('$map-pin')}}),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Open Google Maps")])],1)]}},{key:"item.complete",fn:function(ref){
                              var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","rounded":"","outlined":item.status !== 'completed',"text":item.status === 'completed',"color":"primary"},on:{"click":function($event){return _vm.handle_complete_route(item)}}},[(item.status === 'completed')?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check-circle")]):_vm._e(),_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.status === "completed" ? "Delivered" : "Confirm Delivery"))])],1)]}},{key:"item.edit",fn:function(ref){
                              var item = ref.item;
return [(item.status !== 'completed')?_c('v-btn',{attrs:{"small":"","rounded":"","text":"","color":"accent"},on:{"click":function($event){_vm.selected_route = item;
                _vm.show_some_returned_dialog = true;}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-progress-check")]),_vm._v(" some retured")],1):_c('p',[_vm._v("-")])]}}])})],1)],1)],1),(_vm.show_some_returned_dialog)?_c('v-dialog',{attrs:{"width":"500","content-class":"br-16"},model:{value:(_vm.show_some_returned_dialog),callback:function ($$v) {_vm.show_some_returned_dialog=$$v},expression:"show_some_returned_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text font-weight-bold text-left pb-4"},[_vm._v(" Route "+_vm._s(_vm.selected_route.name)+" ")]),_c('v-card-text',[_c('p',[_vm._v(" Please select undelivered orders and complete route. Undelivered orders will be updated as "),_c('b',[_vm._v("Returned")]),_vm._v(" from delivery ")]),_c('v-list',_vm._l((_vm.order_buffer),function(order){return _c('v-list-item',{key:order.id,on:{"click":function($event){order.delivered = !order.delivered}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                  ((order.shop.id !== _vm.shop_id
                      ? '<b>' + order.shop.name + '</b>'
                      : '') + " Order #" + (order.order_no) + " | " + (order.delivery.postcode))
                )}})],1),_c('v-list-item-icon',[(order.delivered)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-close-circle")])],1)],1)}),1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"black","text":"","rounded":""},on:{"click":function($event){_vm.show_some_returned_dialog = false;
            _vm.order_buffer = [];}}},[_vm._v(" close ")]),_c('v-btn',{attrs:{"elevation":"0","color":"primary","rounded":""},on:{"click":function($event){return _vm.handle_some_returned(_vm.selected_route)}}},[_vm._v(" complete route ")])],1)],1)],1):_vm._e(),(_vm.selected_order)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.shop_details_dialog),callback:function ($$v) {_vm.shop_details_dialog=$$v},expression:"shop_details_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary text-left white--text font-weight-bold pb-4"},[_c('b',[_vm._v(_vm._s(_vm.selected_order.shop.name))]),_vm._v(" Order #"+_vm._s(_vm.selected_order.order_no)+" ")]),_c('v-card-text',[_c('p',[_vm._v(" This order is part of collaborative deliveries. Please find shop contact details below. ")]),_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.shop_details_items),function(item){return _c('v-list-item',{key:item.key},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.selected_order.shop[item.key]))])],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.shop_details_dialog = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
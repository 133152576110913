<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="status_filter"
            :items="statuses"
            label=" Filter by Status"
            hide-details
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" class="px-5 py-3">
          <v-data-table
            :headers="headers"
            :items="routes"
            item-key="id"
            :search="search"
            :items-per-page="10"
            :single-expand="true"
            show-expand
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="px-0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left font-weight-bold">Order</th>
                        <th class="text-left font-weight-bold">Shop</th>
                        <th class="text-left font-weight-bold">Customer</th>
                        <th class="text-left font-weight-bold">Phone</th>
                        <th class="text-left font-weight-bold">Address</th>
                        <th class="text-left font-weight-bold">Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="order in item.orders" :key="order.id">
                        <td>{{ order.order_no }}</td>
                        <td v-if="order.shop && order.shop.id !== shop_id">
                          <v-icon
                            @click="
                              (shop_details_dialog = true),
                                (selected_order = order)
                            "
                            >mdi-information-outline</v-icon
                          >
                          {{ order.shop.name }}
                        </td>
                        <td v-else>
                          {{ shop.name }}
                        </td>
                        <td>{{ order.customer.name }}</td>
                        <td>{{ order.customer.phone_no }}</td>
                        <td>{{ order.delivery.address }}</td>
                        <td>{{ order.customer.note || "-" }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>

            <template v-slot:item.orders="{ item }">
              {{ item.orders.length }}
            </template>

            <template v-slot:item.created_at="{ item }">
              {{ format_date_created_at(item.created_at) }}
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip dark :class="`order-status-chip ${item.status}`">{{
                ORDER_STATUSES[item.status].name
              }}</v-chip>
            </template>

            <template v-slot:item.maps="{ item }">
              <v-btn
                small
                rounded
                text
                :href="route_maps_url(item)"
                target="_blank"
                color="primary"
                ><v-icon class="mr-2" v-text="'$map-pin'" /><span
                  class="font-weight-medium"
                  >Open Google Maps</span
                ></v-btn
              >
            </template>

            <template v-slot:item.complete="{ item }">
              <v-btn
                small
                rounded
                :outlined="item.status !== 'completed'"
                :text="item.status === 'completed'"
                @click="handle_complete_route(item)"
                color="primary"
                ><v-icon v-if="item.status === 'completed'" class="mr-2"
                  >mdi-check-circle</v-icon
                >
                <span class="black--text">{{
                  item.status === "completed" ? "Delivered" : "Confirm Delivery"
                }}</span></v-btn
              >
            </template>

            <template v-slot:item.edit="{ item }">
              <v-btn
                v-if="item.status !== 'completed'"
                small
                rounded
                text
                color="accent"
                @click="
                  selected_route = item;
                  show_some_returned_dialog = true;
                "
                ><v-icon class="mr-2">mdi-progress-check</v-icon> some
                retured</v-btn
              >
              <p v-else>-</p>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="show_some_returned_dialog"
      v-model="show_some_returned_dialog"
      width="500"
      content-class="br-16"
    >
      <v-card>
        <v-card-title
          class="primary white--text font-weight-bold text-left pb-4"
        >
          Route {{ selected_route.name }}
        </v-card-title>

        <v-card-text>
          <p>
            Please select undelivered orders and complete route. Undelivered
            orders will be updated as <b>Returned</b> from delivery
          </p>
          <v-list>
            <v-list-item
              v-for="order in order_buffer"
              :key="order.id"
              @click="order.delivered = !order.delivered"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-html="
                    `${
                      order.shop.id !== shop_id
                        ? '<b>' + order.shop.name + '</b>'
                        : ''
                    } Order #${order.order_no} | ${order.delivery.postcode}`
                  "
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon v-if="order.delivered" color="primary"
                  >mdi-check-circle-outline</v-icon
                >
                <v-icon v-else color="accent">mdi-close-circle</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="black"
            text
            rounded
            @click="
              show_some_returned_dialog = false;
              order_buffer = [];
            "
          >
            close
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            rounded
            @click="handle_some_returned(selected_route)"
          >
            complete route
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selected_order" v-model="shop_details_dialog" width="500">
      <v-card>
        <v-card-title
          class="primary text-left white--text font-weight-bold pb-4"
        >
          <b>{{ selected_order.shop.name }}</b> Order #{{
            selected_order.order_no
          }}
        </v-card-title>

        <v-card-text>
          <p>
            This order is part of collaborative deliveries. Please find shop
            contact details below.
          </p>
          <v-list two-line>
            <v-list-item v-for="item in shop_details_items" :key="item.key">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  selected_order.shop[item.key]
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="shop_details_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import { mapState } from "vuex";
import { ORDER_STATUSES } from "../dashboard_data";

import { complete_delivery_route } from "@/requests";

export default {
  name: "Deliveries",
  data() {
    return {
      ORDER_STATUSES: {
        ...ORDER_STATUSES,
        planned: { id: "planned", name: "Planned" },
      },

      search: "",
      status_filter: null,
      order_buffer: [],

      shop_details_dialog: false,
      selected_order: null,

      selected_route: null,
      show_some_returned_dialog: false,
      statuses: ["Planned", "Completed"],
      shop_details_items: [
        {
          icon: "mdi-map-marker",
          label: "Location",
          key: "location",
        },
        {
          icon: "mdi-cellphone",
          label: "Mobile",
          key: "mobile_no",
        },
        {
          icon: "mdi-phone",
          label: "Phone",
          key: "phone_no",
        },
      ],
    };
  },
  computed: {
    ...mapState(["server_url"]),
    ...mapState("AdminStore", ["shop", "shop_id", "routes"]),
    headers() {
      return [
        { text: "Route", value: "name" },
        { text: "Created", value: "created_at" },
        { text: "Orders ", value: "orders" },
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.status_filter) return true;
            return value === this.status_filter.toLowerCase();
          },
          align: "center",
        },
        { text: "Directions", value: "maps", sortable: false, align: "center" },
        {
          text: "Delivery Action",
          value: "complete",
          sortable: false,
          align: "center",
        },
        {
          text: "Returns Action",
          value: "edit",
          sortable: false,
          align: "center",
        },
        { text: "", value: "data-table-expand", sortable: false },
      ];
    },
    table_title() {
      const planned_routes = this.routes.filter((r) => r.status === "planned");

      if (planned_routes.length === 0) return "There is no planned deliveries";
      return `You have ${planned_routes.length} route${
        planned_routes.length > 1 ? "s" : ""
      } planned`;
    },
  },
  watch: {
    selected_route: function (route) {
      if (route) {
        for (let order of route.orders) {
          this.order_buffer.push({ ...order, delivered: true });
        }
      } else {
        this.order_buffer = [];
      }
    },
    show_some_returned_dialog: function (show) {
      if (!show) {
        this.selected_route = null;
      }
    },
  },
  methods: {
    route_maps_url(route) {
      let url = "https://www.google.com/maps/dir/?api=1";
      let origin = "origin=My+Location";
      let travel_mode = `travelmode=${route.mode.toLowerCase()}`;
      let waypoints = [];
      let destination = "destination=";

      for (let i = 0; i < route.directions.length; i++) {
        let dir = route.directions[i];
        // encode according to google maps url schema
        dir = dir.replace("//s/g", "+");
        dir = dir.replace("/,/g", "%2C");

        if (i === route.directions.length - 1) {
          destination += encodeURIComponent(`${dir}`);
        } else {
          waypoints.push(encodeURIComponent(dir));
        }
      }
      url += `&${origin}&${travel_mode}&${destination}&waypoints=${waypoints.join(
        "|"
      )}`;

      return url;
    },
    format_date_created_at(created_at) {
      if (!created_at) return;

      return moment.unix(created_at.seconds).format("DD/MM/YYYY hh:mm");
    },
    async handle_complete_route(route) {
      if (route.status === "completed") return;
      const { value: accept } = await Swal.fire({
        title: "Confirm",
        text: `Please confirm you delivered ${route.orders.length} orders?`,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#54c4c9",
        showCancelButton: true,
      });

      if (accept) {
        const route_orders = route.orders.map((o) => ({
          shop_id: o.shop.id,
          order_id: o.order_id,
          delivered: true,
        }));

        await this.complete_route(route.id, route_orders);
      }
    },
    async handle_some_returned() {
      const route_orders = this.order_buffer.map((o) => ({
        shop_id: o.shop.id,
        order_id: o.order_id,
        delivered: o.delivered,
      }));
      this.show_some_returned_dialog = false;
      await this.complete_route(this.selected_route.id, route_orders);
    },
    async complete_route(route_id, route_orders) {
      try {
        await complete_delivery_route({
          route_id,
          shop_id: this.shop_id,
          shop_name: this.shop.name,
          route_orders,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
